import CoursePage from '@pages/CoursePage/CoursePage'
import CoursesCatalogPage from '@pages/CoursesCatalogPage/CoursesCatalogPage'
import TimetablePage from '@pages/TimetablePage/TimetablePage'
import { isIEBrowser, isSafariBrowser } from '@utils/ie'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import React, { useEffect } from 'react'
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import MainPage from '@pages/MainPage/MainPage'
import { useQuery } from 'react-query'
import { settingsApi } from '@api/requestFunctions'
import { SettingsResp } from 'types/apiTypes'
import { store } from '@store/index'
import MainNavbarLayout from '@components/layout/MainNavbarLayout/MainNavbarLayout'
import FooterLayout from '@components/layout/FooterLayout/FooterLayout'
import ContactsPage from '@pages/ContactsPage/ContactsPage'
import TeachersPage from '@pages/TeachersPage/TeachersPage'
import TeacherPage from '@pages/TeacherPage/TeacherPage'
import PaymentOrderPage from '@pages/PaymentOrderPage/PaymentOrderPage'
import InstitutePage from '@pages/InstitutePage/InstitutePage'
import { useUtm } from '@utils/hooks/useUtm'

function App(props: RouteComponentProps) {
    const {
        location, history,
    } = props

    // console.log('location', location);


    useQuery<SettingsResp, Error>(
        'get-site-settings',
        settingsApi.getAllSettings,
        {
            retry: 2,
            refetchOnWindowFocus: false,
            onSuccess: data => store.settings.updateInit(data),
            onError: err => console.error('Initial request site settings error:', err)
        }
    )

    useEffect(() => {
        if (isIEBrowser()) document.body.classList.add('ie')
        if (isSafariBrowser()) document.body.classList.add('safari')
    }, [])

    useEffect(() => {
        const str = `
        <!-- Marquiz script start -->
<script>
(function(w, d, s, o){
  var j = d.createElement(s); j.async = true; j.src = '//script.marquiz.ru/v2.js';j.onload = function() {
    if (document.readyState !== 'loading') Marquiz.init(o);
    else document.addEventListener("DOMContentLoaded", function() {
      Marquiz.init(o);
    });
  };
  d.head.insertBefore(j, d.head.firstElementChild);
})(window, document, 'script', {
    host: '//quiz.marquiz.ru',
    region: 'eu',
    id: '64ca2fb7286519002527cae4',
    autoOpen: 3,
    autoOpenFreq: 'always',
    openOnExit: false,
    disableOnMobile: false
  }
);
</script>
<!-- Marquiz script end -->
        `;
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.text = str.replace(/<[^>]*>?/gm, '');
        head.appendChild(script);

        return () => {
            head.removeChild(script);
        }
    }, [])

    useUtm(history, location)

    return (
        <>
            <ErrorBoundary>
                <MainNavbarLayout>
                    <FooterLayout>
                        <Switch>
                            <Route path="/" exact component={MainPage} />
                            <Route path="/course/:courseId" exact component={CoursePage} />
                            <Route path="/coursesCatalog" exact component={CoursesCatalogPage} />
                            <Route path="/timeTable" exact component={TimetablePage} />
                            <Route path="/contacts" exact component={ContactsPage} />
                            <Route path="/teachers" exact component={TeachersPage} />
                            <Route path="/teacher/:id" exact component={TeacherPage} />
                            <Route path="/paymentOrder" exact component={PaymentOrderPage} />
                            <Route path="/institute" exact component={InstitutePage} />

                            <Redirect from="/l/:ord" to="/paymentOrder?ord=:ord"/>
                            <Redirect to="/" />

                        </Switch>
                    </FooterLayout>
                </MainNavbarLayout>
            </ErrorBoundary>
        </>
    )
}

export default withRouter(App)
